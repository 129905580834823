/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function n() {
  return [0, 0, 0];
}
function t(n) {
  return [n[0], n[1], n[2]];
}
function r(n, t, r) {
  return [n, t, r];
}
function e(n, t, r) {
  return [n, t, r];
}
function u(t, r = n()) {
  const e = Math.min(3, t.length);
  for (let n = 0; n < e; ++n) r[n] = t[n];
  return r;
}
function o(n, t) {
  return new Float64Array(n, t, 3);
}
function c() {
  return n();
}
function i() {
  return r(1, 1, 1);
}
function f() {
  return r(1, 0, 0);
}
function a() {
  return r(0, 1, 0);
}
function l() {
  return r(0, 0, 1);
}
const _ = c(),
  s = i(),
  m = f(),
  y = a(),
  N = l(),
  O = Object.freeze(Object.defineProperty({
    __proto__: null,
    ONES: s,
    UNIT_X: m,
    UNIT_Y: y,
    UNIT_Z: N,
    ZEROS: _,
    clone: t,
    create: n,
    createView: o,
    freeze: e,
    fromArray: u,
    fromValues: r,
    ones: i,
    unitX: f,
    unitY: a,
    unitZ: l,
    zeros: c
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { s as ONES, m as UNIT_X, y as UNIT_Y, N as UNIT_Z, _ as ZEROS, t as clone, n as create, o as createView, e as freeze, u as fromArray, r as fromValues, i as ones, f as unitX, a as unitY, l as unitZ, O as v, c as zeros };